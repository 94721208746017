<template>
  <div class="blog text-center">
    <h1 class="mt-1" style="font-family: 'Raleway light', sans-serif">{{ $t("blog_page.title") }}</h1>
    <p style="font-size: 20px">
      {{ $t("blog_page.description") }}
    </p>
    <v-row class="blog-list mt-5">
      <v-col cols="12" md="4" sm="12" v-for="item of getBlogs" :key="item.id" style="height: 100%">
        <BlogItem :item="item" />
      </v-col>
    </v-row>
    <Footer bg-color="#b9b9b9"/>
  </div>
</template>
<script>

export default {
  name: "Blog",
  components: {
    Footer: () => import("@/components/Footer"),
    BlogItem: () => import("@/components/Account/BlogItem")
  },
  computed: {
    getBlogs() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.blogs.reverse();
    }
  },
  data: () => ({
    blogs: [
      {
        id: 1,
        date: "02 Sep, 2023",
        title: "blog_page.blog1_title",
        img: require("/src/assets/img/how-market.png"),
        description: "blog_page.blog1_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 2,
        date: "02 Sep, 2023",
        title: "blog_page.blog2_title",
        img: require("/src/assets/img/how-get-customers.png"),
        description: "blog_page.blog2_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 3,
        date: "02 Sep, 2023",
        title: "blog_page.blog3_title",
        img: require("/src/assets/img/maximazing-your-efforts.png"),
        description: "blog_page.blog3_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 4,
        date: "02 Sep, 2023",
        title: "blog_page.blog4_title",
        img: require("/src/assets/img/maximazing-your-potensial.png"),
        description: "blog_page.blog4_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 5,
        date: "02 Sep, 2023",
        title: "blog_page.blog5_title",
        img: require("/src/assets/img/how-promote.png"),
        description: "blog_page.blog5_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 6,
        date: "02 Sep, 2023",
        title: "blog_page.blog6_title",
        img: require("/src/assets/img/how-aquire.png"),
        description: "blog_page.blog6_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 7,
        date: "02 Sep, 2023",
        title: "blog_page.blog7_title",
        img: require("/src/assets/img/future-digital.png"),
        description: "blog_page.blog7_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
      {
        id: 8,
        date: "02 Sep, 2023",
        title: "blog_page.blog8_title",
        img: require("/src/assets/img/maximizing-ad.png"),
        description: "blog_page.blog8_description",
        views: 203,
        messages: 22,
        shareCount: 7,
      },
    ],
  }),
};
</script>
<style scoped lang="scss">
.blog {
  padding-bottom: 40px;

  h1 {
    color: white;
    font-weight: bolder;
    font-size: 40px;
    @media (max-width: 800px) {
      margin-top: 25px;
    }
    @media (max-width: 600px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 20px;
    padding: 0 150px;
    margin-top: 25px;
    color: white;
    @media (max-width: 800px) {
      padding: 0 15px;
    }
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  .blog-list {
    margin-top: 140px !important;
    @media (max-width: 600px) {
      margin-top: 55px !important;
    }
    @media (max-width: 400px) {
      margin-top: 40px !important;
    }
  }
}
</style>
